<template>
  <div ref="catalogRef" class="section-catalog py-10 lg:py-28">
    <Heading
      v-if="data.title"
      semantic
      class="mx-auto mb-6 max-w-2xl px-4 text-center text-3xl font-light text-blue-900 lg:mb-12 lg:px-0 lg:text-6xl"
    >
      {{ data.title }}
    </Heading>
    <div v-if="data.html" class="mb-6 flex justify-center lg:mb-10">
      <div
        class="max-w-2xl text-center text-base font-normal text-blue-950 lg:text-2xl"
        v-html="data.html"
      ></div>
    </div>

    <div
      v-for="(button, idx) in data.buttons"
      :key="idx"
      class="container mb-14 flex max-w-2xl justify-center lg:mb-20"
    >
      <Button
        :label="button.label"
        :to="
          button.reference !== null
            ? localePathByType(
                button.reference?.__typename as string,
                button.reference?.slug as string
              )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="'solid'"
        :color="button.variant === 'solid' ? 'default' : 'gray'"
        :class="'mt-6 lg:mt-8'"
        :icon="button.icon"
      />
    </div>
    <div
      ref="containerRef"
      class="relative aspect-video h-[100px] w-full overflow-hidden lg:h-[212px]"
    >
      <Image
        v-for="(image, index) in data.images"
        :key="index"
        ref="images"
        :loading="'lazy'"
        :src="image!.responsiveImage ? image!.responsiveImage?.src : image!.url"
        :srcset="image!.responsiveImage ? image!.responsiveImage?.webpSrcSet : undefined"
        :width="image!.responsiveImage ? image!.responsiveImage?.width : image!.width"
        :height="image!.responsiveImage ? image!.responsiveImage?.height : image!.height"
        :sizes="image?.responsiveImage?.sizes"
        :alt="image!.alt!"
        class="img absolute left-1/2 h-[100px] w-[100px] -translate-x-[50%] object-cover lg:h-[200px] lg:w-[200px]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type { SectionCatalogRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const { localePathByType } = useLocalePathByType()
const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')

defineProps({
  data: {
    type: Object as PropType<SectionCatalogRecord>,
    default: () => {}
  }
})

const animateImages = (images: Element[], gap: number, direction: string) => {
  images?.forEach((image: Element, index: number) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: catalogRef.value,
        start: 'top 50%',
        end: 'bottom 50%',
        scrub: true
      }
    })

    const imgWidth = image.clientWidth * 1.2
    const containerWidth = isScreenLg.value ? 300 : 0
    const center = (containerWidth - imgWidth) / 2
    const offset =
      (direction === 'right' ? index + 1 : index) * gap * (direction === 'left' ? -1 : 1)

    tl.to(image, {
      x: center + offset,
      duration: 1
    })
  })
}

const catalogRef = ref<HTMLElement | null>(null)
const containerRef = ref<HTMLElement | null>(null)

onMounted(async () => {
  gsap.registerPlugin(ScrollTrigger)

  await nextTick(() => {
    if (containerRef.value !== null) {
      ScrollTrigger.create({
        trigger: catalogRef.value,
        start: 'top 50%',
        end: 'bottom 50%',
        scrub: true
      })

      const images = containerRef.value?.children
      const imagesLeft = Array.from(images!).filter((_, index) => index % 2 === 0)
      const imagesRight = Array.from(images!).filter((_, index) => index % 2 !== 0)
      const gap = isScreenLg.value ? 300 : 120

      animateImages(imagesLeft, gap, 'left')
      animateImages(imagesRight, gap, 'right')
    }
  })
})

onUnmounted(() => {
  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>
